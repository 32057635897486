import {backendApi} from "../../backend-api-sr"

export const cibam = (() =>{
    const fetchIndex = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/emision'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const fetchOne = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/emision/trae-uno'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const insert = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/emision/crear'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    const update = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/emision/actualizar'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    

    return {fetchIndex, fetchOne, insert, update};
})()