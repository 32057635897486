<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-card outlined>
                <v-container fluid>
                    <v-row>
                        <v-col class="col-6" sm="6" md="2">
                            <span class="blue-lcd mb-2 font-12">Year </span>
                            <v-menu ref="yearModal" v-model="yearModal" transition="scale-transition"
                                offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="ma-0 mt-2 border-12" v-model="year" dense clearable outlined
                                        persistent-hint append-icon="mdi-calendar"
                                        v-bind="attrs" v-on="on" hide-details=true>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="year" type="year"
                                    no-title @input="yearModal = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col class="col-6" sm="6" md="2">
                            <span class="blue-lcd mb-2 font-12">Start Period </span>
                            <v-menu ref="startDateModal" v-model="startDateModal" transition="scale-transition"
                                offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="ma-0 mt-2 border-12" v-model="startDate" dense clearable outlined
                                        persistent-hint append-icon="mdi-calendar"
                                        v-bind="attrs" v-on="on" hide-details=true>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="startDate" type="month"
                                    no-title @input="startDateModal = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <span class="blue-lcd mb-2 font-12">End Period </span>
                            <v-menu ref="endDateModal"
                            v-model="endDateModal"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    dense
                                    clearable
                                    outlined
                                    v-model="endDate"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 mt-2 border-12"
                                    hide-details=true>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="endDate" type="month"
                                    no-title @input="endDateModal = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <span class="blue-lcd mb-2 font-12">Type </span>
                            <v-autocomplete
                                outlined
                                dense
                                clearable
                                v-model="type"
                                :items="types"
                                default="" 
                                item-value="desc" 
                                item-text="desc"
                                class="ma-0 pa-0 mt-2 border-12"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-12" sm="12" md="4" lg="4">
                            <v-btn class="mt-4 mr-2 border-12" style="padding: 19px;" color="info" elevation="2" small @click="getData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            <v-btn class="mt-4 ml-2 border-12 success" style="padding: 19px;" elevation="2" small @click="createDialog()"><v-icon>mdi-file-outline</v-icon> Create</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-row>

        <v-dialog class="rounded-xl" v-model="dialog" max-width="1200px"  transition="dialog-bottom-transition" style="background: #fff;">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" class="p-0" style="padding:0px !important">
                        <v-card class="rounded-xl elevation-12" style="background-position: center; background-repeat: no-repeat; background-size: cover;">
                            <v-card-title style="font-weight:600">
                                <v-row align="center" justify="center" style="margin-top: -16px;">
                                    <v-col cols="12" xs="12" sm="6" md="4" class="primary" style="border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;">
                                        <h5 class="text-white text-center">
                                            {{ dialogText }}
                                        </h5>
                                    </v-col>
                                </v-row>
                            </v-card-title>

                            <v-card-text>
                                <v-container fluid>
                                    <v-row class="mt-lg-5">
                                        <v-col class="col-6" sm="6" md="2">
                                            <span class="blue-lcd mb-2 font-12">Year </span>
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="320px"
                                                min-width="auto"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        dense
                                                        v-model="yearDialog"
                                                        label="Year"
                                                        append-icon="event"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        solo
                                                        class="ma-0 border-12"
                                                        readonly
                                                        hide-details=true
                                                    >
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                    ref="picker"
                                                    v-model="date"
                                                    @input="save_date_to_year"
                                                    reactive
                                                    no-title
                                                    :max="picker"
                                                    type="year">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="2">
                                            <span class="blue-lcd mb-2 font-12">Start Period </span>
                                            <v-menu ref="startDateDialogModal" v-model="startDateDialogModal" transition="scale-transition"
                                                offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field class="ma-0 mt-2 border-12" v-model="startDateDialog" :disabled="dialogDisableEdit" dense clearable outlined
                                                        persistent-hint append-icon="mdi-calendar"
                                                        v-bind="attrs" v-on="on" hide-details=true>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="startDateDialog" type="month"
                                                    no-title @input="startDateDialogModal = false">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="2">
                                            <span class="blue-lcd mb-2 font-12">End Period </span>
                                            <v-menu ref="endDateDialogModal"
                                            v-model="endDateDialogModal"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    dense
                                                    :disabled="dialogDisableEdit"
                                                    clearable
                                                    outlined
                                                    v-model="endDateDialog"
                                                    persistent-hint
                                                    append-icon="mdi-calendar"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="ma-0 mt-2 border-12"
                                                    hide-details=true>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="endDateDialog" type="month"
                                                    no-title @input="endDateDialogModal = false">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="3">
                                            <p class="blue-lcd ma-0 pa-0">Type</p>
                                            <v-autocomplete 
                                                dense
                                                :disabled="dialogDisableEdit" 
                                                solo 
                                                class="ma-0 pa-0 border-12" 
                                                hide-details=true 
                                                v-model="typeDialog"
                                                :items="types" 
                                                default="" 
                                                item-value="desc" 
                                                item-text="desc" 
                                                label="Type" 
                                                clearable>
                                              </v-autocomplete>  
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="3">
                                            <p class="blue-lcd ma-0 pa-0">Activity</p>
                                            <v-autocomplete 
                                                dense
                                                :disabled="dialogDisableEdit" 
                                                solo 
                                                class="ma-0 pa-0 border-12" 
                                                hide-details=true 
                                                v-model="activityDialog"
                                                :items="activityDialogs" 
                                                default="" 
                                                item-value="desc" 
                                                item-text="desc" 
                                                label="Activity" 
                                                clearable>
                                              </v-autocomplete>  
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="2">
                                            <p class="blue-lcd ma-0 pa-0">UOM</p>
                                            <v-autocomplete 
                                                dense
                                                :disabled="dialogDisableEdit" 
                                                solo 
                                                class="ma-0 pa-0 border-12" 
                                                hide-details=true 
                                                v-model="uomDialog"
                                                :items="uomDialogs" 
                                                default="" 
                                                item-value="desc" 
                                                item-text="desc" 
                                                label="UOM" 
                                                clearable>
                                              </v-autocomplete>  
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="2">
                                            <p class="blue-lcd ma-0 pa-0">Total</p>
                                            <v-text-field
                                                solo
                                                label="12"
                                                persistent-hint
                                                required
                                                disabled
                                                v-model="ytd"
                                                class="ma-0 pa-0 border-12"
                                                hide-details
                                                type = "number"
                                            >
                                            </v-text-field>     
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="2">
                                            <p class="blue-lcd ma-0 pa-0">CO 2</p>
                                            <v-text-field
                                                solo
                                                label="12"
                                                persistent-hint
                                                required
                                                v-model="coTwo"
                                                class="ma-0 pa-0 border-12"
                                                hide-details
                                                type = "number"
                                            >
                                            </v-text-field>     
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="2">
                                            <p class="blue-lcd ma-0 pa-0">CH 4</p>
                                            <v-text-field
                                                solo
                                                label="12"
                                                persistent-hint
                                                required
                                                v-model="chFour"
                                                class="ma-0 pa-0 border-12"
                                                hide-details
                                                type = "number"
                                            >
                                            </v-text-field>     
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="2">
                                            <p class="blue-lcd ma-0 pa-0">N2O</p>
                                            <v-text-field
                                                solo
                                                label="12"
                                                persistent-hint
                                                required
                                                v-model="nTwoo"
                                                class="ma-0 pa-0 border-12"
                                                hide-details
                                                type = "number"
                                            >
                                            </v-text-field>     
                                        </v-col>
                                        <v-col class="col-6" sm="6" md="2">
                                            <p class="blue-lcd ma-0 pa-0">Emisi CO2</p>
                                            <v-text-field
                                                solo
                                                label="12"
                                                persistent-hint
                                                disabled
                                                required
                                                v-model="emisiCo"
                                                class="ma-0 pa-0 border-12"
                                                hide-details
                                                type = "number"
                                            >
                                            </v-text-field>     
                                        </v-col>
                                    </v-row>
                                    
                                    <v-row justify="end" class="mt-8 mr-2">
                                        <v-col cols="12" xs="12" sm="6" md="1">
                                            <v-btn class="mt-0 mr-2 border-12 success" type="submit" @click="generate()">Generate</v-btn>    
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-data-table
                                            fixed-header
                                            height="525"
                                            :headers="header_items"
                                            :items="detailItems"
                                            hide-default-footer
                                            loading-text="Please wait, retrieving data"
                                            items-per-page="12"
                                            class="elevation-1 mt-2"
                                        >
                                            <!-- <template v-slot:[`item.period`]="{ item }">
                                                <v-text-field
                                                    solo
                                                    dense
                                                    class="border-12 ma-0 pa-0"
                                                    v-model="item.period"
                                                    single-line
                                                    hide-details    
                                                ></v-text-field>
                                            </template> -->
                                            <template v-slot:[`item.value`]="{ item }">
                                                <v-text-field
                                                    solo
                                                    dense
                                                    class="border-12 ma-0 pa-0"
                                                    v-model="item.value"
                                                    single-line
                                                    hide-details
                                                    type="number"
                                                ></v-text-field>
                                            </template>
                                        </v-data-table>
                                    </v-row>

                                    <v-row justify="center" class="mt-2">
                                        <!-- <v-col cols="12" xs="12" sm="6" md="4"></v-col> -->
                                        <v-col cols="12" xs="12" sm="6" md="1">
                                            <v-btn class="mt-4 mr-2 border-12" color="#fff" @click=closeDialog()>Close</v-btn>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="1">
                                            <v-btn class="mt-4 mr-2 border-12 success" type="submit" @click="submit()">{{ dialogButton }}</v-btn>    
                                        </v-col>
                                        <!-- <v-col cols="12" xs="12" sm="6" md="4"></v-col> -->
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-dialog>

        <v-row class="mb-2">
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    item-class="tr_datatable"
                                    :items-per-page="10"
                                    fixed-header
                                    dense
                                    height="450"
                                    :divider="true"
                                    :light="true"
                                    :search="search"
                                >
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-100">
                                            <v-spacer></v-spacer>
                                            <v-text-field outlined style="max-width: 300px;" class="border-12 mr-3 mt-2" v-model="search" append-icon="mdi-magnify" dense  hide-details></v-text-field>
                                            <v-btn small
                                                color="#005c37"
                                                class="py-5 mr-3 text-white border-12"
                                                @click="exportExcel()"><v-icon>mdi-file-excel</v-icon> Export Excel</v-btn>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.action`]="{ item }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small class="mr-2" @click="editDialog(item)" v-on="on">
                                                mdi-pencil
                                            </v-icon>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>
                                </template>
                                <template v-slot:[`item.01`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['01']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.02`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['02']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.03`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['03']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.04`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['04']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.05`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['05']) }}
                                    </div>
                                </template>

                                <template v-slot:[`item.06`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['06']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.07`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['07']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.08`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['08']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.09`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['09']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.10`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['10']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.11`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['11']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.12`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item['12']) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.ytd`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item.ytd) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.co2`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item.co2) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.ch4`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item.ch4) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.n2o`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item.n2o) }}
                                    </div>
                                </template>
                                <template v-slot:[`item.emisi_co2`]="{ item }">
                                    <div class="mt-0">
                                        {{ $store.getters.convertToCurrencyUs(item.emisi_co2) }}
                                    </div>
                                </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {cibam} from "../../../backend-api/sr/production/cibam"
import exportFromJSON from "export-from-json"

export default {
    data(){
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Production',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Cibam',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            yearModal:false,
            year:'',
            startDateModal:false,
            startDate:'',
            endDateModal:false,
            endDate:'',
            type:'',
            types:[
                {'desc': "Direct Emission"},
                {'desc': "Indirect Emission"},
            ],
            header:[
                {text: 'Edit', align: 'center', value: 'action'},
                { text: 'Year', value: 'year'},
                { text: 'Period Start', value: 'period_start'},
                { text: 'Period End', value: 'period_end'},
                { text: 'Type', value: 'type'},
                { text: 'Activity', value: 'activity'},
                { text: 'UOM', value: 'uom'},
                { text: '01', value: '01'},
                { text: '02', value: '02'},
                { text: '03', value: '03'},
                { text: '04', value: '04'},
                { text: '05', value: '05'},
                { text: '06', value: '06'},
                { text: '07', value: '07'},
                { text: '08', value: '08'},
                { text: '09', value: '09'},
                { text: '10', value: '10'},
                { text: '11', value: '11'},
                { text: '12', value: '12'},
                { text: 'Total', value: 'ytd'},
                { text: 'CO2', value: 'co2'},
                { text: 'CH4', value: 'ch4'},
                { text: 'N2O', value: 'n2o'},
                { text: 'Emisi CO2', value: 'emisi_co2'}
            ],
            data : [],
            search : '',
            dialog : false,
            dialogText : "Create",
            dialogButton : "Submit",
            dialogDisableEdit:false,
            menu: false,
            date:'',
            // picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            picker:'',
            yearDialogModal:false,
            yearDialog:'',
            startDateDialogModal:false,
            startDateDialog:'',
            endDateDialogModal:false,
            endDateDialog:'',
            activityDialog:'',
            activityDialogs:[
                {'desc': "Natural Gas"},
                {'desc': "Listrik dari pihak ketiga"},
            ],
            uomDialog:'',
            uomDialogs:[
                {'desc': "Kwh"},
                {'desc': "Tj"},
            ],
            typeDialog:'',
            one:0,
            two:0,
            three:0,
            four:0,
            five:0,
            six:0,
            seven:0,
            eight:0,
            nine:0,
            ten:0,
            eleven:0,
            twelve:0,
            ytd:0,
            coTwo:0,
            chFour:0,
            nTwoo:0,
            emisiCo:0,
            header_items:[
                { text: 'Period', value: 'period'},
                { text: 'Bulan', value: 'bulan'},
                { text: 'Value', value: 'value'},
            ],
            detailItems:[],
        }
    },
    computed: {
    },
    mounted(){
        this.picker = this.setMaxYear(3)
    },
    methods:{
        setMaxYear(a){
            let currentDate = new Date(Date.now());
            currentDate.setFullYear(currentDate.getFullYear() + a);
            return (new Date(currentDate - (currentDate).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        },
        reset(){
            this.$store.dispatch('setOverlay', false)
            this.dialog = false
            this.dialogText = "Create"
            this.dialogButton = "Submit"
            this.dialogDisableEdit=false
            this.startDateDialogModal=false
            this.startDateDialog=''
            this.endDateDialogModal=false
            this.endDateDialog=''
            this.activityDialog=''
            this.typeDialog=''
            this.uomDialog=''
            this.one=0
            this.two=0
            this.three=0
            this.four=0
            this.five=0
            this.six=0
            this.seven=0
            this.eight=0
            this.nine=0
            this.ten=0
            this.eleven=0
            this.twelve=0
            this.ytd=0
            this.coTwo=0
            this.chFour=0
            this.nTwoo=0
            this.emisiCo=0
            this.detailItems=[]
            this.date=''
            this.picker=''
        },
        save_date_to_year(date) {
            this.$refs.menu.save(date)

            this.$refs.picker.activePicker = 'YEAR'

            this.menu = false

            this.yearDialog = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        async getData(){
            var startDate = this.startDate ? new Date(this.startDate).toISOString().slice(0, 7) : ''
            var endDate = this.endDate ? new Date(this.endDate).toISOString().slice(0, 7) : ''
            if(startDate == ''){
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Start periode is a must.",
                    visible: true
                };
                return;
            }
            this.$store.dispatch('setOverlay', true)
            let reqBody = {
                "period_start_a" : startDate.replace("-",""),
                "period_start_b" : endDate ? endDate.replace("-","") : '',
                "type" : this.type,
                "activity" : ""
            }
            const respData = await cibam.fetchIndex('', reqBody)
            if (respData.status === 200) {
                this.data = respData.data.data
                this.$store.dispatch('setOverlay', false)
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },
        
        async createDialog(){
            this.dialog = true
        },
        async editDialog(item){
            console.log(item)
            this.dialog = true
            this.dialogText = "Update"
            this.dialogButton = "Update"
            this.dialogDisableEdit=true
            var reqBody = {
                "period_start" : item.period_start,
                "type" : item.type,
                "activity" : item.activity
            }
            const respData = await cibam.fetchOne('', reqBody)
            if (respData.status === 200) {
                const d = respData.data.data
                this.startDateDialog= d.period_start.slice(0, 4) + "-" + d.period_start.slice(4)
                this.endDateDialog= d.period_end.slice(0, 4) + "-" + d.period_end.slice(4)
                this.activityDialog= d.activity
                this.typeDialog= d.type
                this.uomDialog= d.uom
                this.one=d['01']
                this.two=d['02']
                this.three=d['03']
                this.four=d['04']
                this.five=d['05']
                this.six=d['06']
                this.seven=d['07']
                this.eight=d['08']
                this.nine=d['09']
                this.ten=d['10']
                this.eleven=d['11']
                this.twelve=d['12']
                this.coTwo=d.co2
                this.chFour=d.ch4
                this.nTwoo=d.n2o
                this.ytd = d.ytd
                this.emisiCo = d.emisi_co2
                this.$store.dispatch('setOverlay', false)
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },
        async closeDialog(){
            this.reset()
            await this.getData()
        },
        generate(){
            const data = [];
            console.log(this.yearDialog)
            let year = parseInt(this.yearDialog);
            var startPeriod = this.startDateDialog ? new Date(this.startDateDialog).toISOString().slice(0, 7).replace("-", "") : ''
            let month = parseInt(startPeriod.slice(4), 10);

            for (let i = 0; i < 12; i++) {
                const period = `${year}${String(month).padStart(2, '0')}`;
                data.push({
                    period: period,
                    bulan: String(i + 1).padStart(2, '0'),
                    // value: i === 0 ? "19.21" : "0.0"
                    value: 0
                });

                // Increment month and adjust year if month exceeds 12
                month += 1;
                if (month > 12) {
                    month = 1;
                    year += 1;
                }
            }
            this.detailItems = data
        },
        async submit(cond){
            var startDate = this.startDateDialog ? new Date(this.startDateDialog).toISOString().slice(0, 7).replace("-", "") : ''
            var endDate = this.endDateDialog ? new Date(this.endDateDialog).toISOString().slice(0, 7).replace("-", "") : ''
            var reqBody = {
                'id':this.id,
                'year':this.year,
                'period_start' : startDate,
                'period_end' : endDate,
                'type' : this.typeDialog,
                'activity' : this.activityDialog,
                'uom' : this.uomDialog,
                '01' : this.one,
                '02' : this.two,
                '03' : this.three,
                '04' : this.four,
                '05' : this.five,
                '06' : this.six,
                '07' : this.seven,
                '08' : this.eight,
                '09' : this.nine,
                '10' : this.ten,
                '11' : this.eleven,
                '12' : this.twelve,
                'co2' : this.coTwo,
                'ch4' : this.chFour,
                'n2o' : this.nTwoo,
                'detail' : this.detailItems
            }
            var respData = ''
            if(this.dialogText == "Update"){
                respData = await cibam.update('', reqBody)
            } else {
                respData = await cibam.insert('', reqBody)
                if (respData.status === 200) {
                    this.dialogText = "Update"
                    this.dialogButton = "Update"
                }
            }
            if (respData.status === 200) {
                const d = respData.data.data
                this.ytd = d.ytd
                this.emisiCo = d.emisi_co2
                this.$store.dispatch('setOverlay', false)
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },

        exportExcel(){
            const data = this.data
            const fileName = "Cibam"
            const exportType = exportFromJSON.types.csv

            if (data) exportFromJSON({ data, fileName, exportType })

            Swal.fire({
                title: 'Success',
                text: 'Data exported successfully, check your download folder.'
            })
        },
    }
}
</script>